// VARIABLES

// FUNCIONES
function start() {

    //--- fix placeholder
    $(function () {
        var input = document.createElement("input");
        if (('placeholder' in input) == false) {
            $('[placeholder]').focus(function () {
                var i = $(this);
                if (i.val() == i.attr('placeholder')) {
                    i.val('').removeClass('placeholder');
                    if (i.hasClass('password')) {
                        i.removeClass('password');
                        this.type = 'password';
                    }
                }
            }).blur(function () {
                var i = $(this);
                if (i.val() == '' || i.val() == i.attr('placeholder')) {
                    if (this.type == 'password') {
                        i.addClass('password');
                        this.type = 'text';
                    }
                    i.addClass('placeholder').val(i.attr('placeholder'));
                }
            }).blur().parents('form').submit(function () {
                $(this).find('[placeholder]').each(function () {
                    var i = $(this);
                    if (i.val() == i.attr('placeholder'))
                        i.val('');
                });
            });
        }
    });

    //--- Setimeout for ANGULAR
    setTimeout(function () {

        //--- Modal
        // Search
        $(".js-modal-search").click(function (e) {
            e.preventDefault();
            $(".modal-search").modal("show");
        });
        // Medium
        $(".js-modal-group").click(function (e) {
            e.preventDefault();
            $(".modal--medium").modal("show");
        });

        //--- Open drop
        $(".js-open-drop").click(function (e) {
            e.preventDefault();
            $(this).toggleClass("active");
            $(this).next(".drop-section").slideToggle();
        });

        //--- Btn link
        $(".btn-link ").click(function () {
            $(this).parent(".header__title").toggleClass("active");
            $(this).parents(".accordion").toggleClass("active");
        });

        //--- Scroll to
        $(".js-scroll-to a").click(function (e) {
            e.preventDefault();
            $('#target' + $(this).attr("data-target")).addClass('nactive');
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });

        //--- Grid check
        $(".js-radio-check").on('keyup change drop paste touchstart', function () {
            if (this.checked) {
                //Do stuff
                $(".grid__check").removeClass("active");
                $(this).parent(".grid__check").addClass("active");
            }
        });

        //--- Chart level
        $(".js-org-chart").click(function () {
            setTimeout(function () {
                // Level 0
                var levelZero = $(".level--0").height();
                var levelOne = $(".level--1:last-child").height();
                $(".level--0 > .the-line").height(levelZero - levelOne + 10);

                // Level 1
                $(".level--1 .the-line").each(function () {
                    var levelTwo = $(this).parent(".level--1").height();
                    var levelChildren = $(this).parent(".level--1").find(".level--line-children:last-child").height();
                    $(this).height(levelTwo - levelChildren + 12);
                });

            }, 1000);
        });

        //--- Group list
        // $(".js-group-list").click(function (e) {
        //     e.preventDefault();
        //     $(this).toggleClass("active");
        //     $(this).next(".drop-group").stop().slideToggle();
        // });

        var tagiInput = $('input[name=tags]').size();

        if (tagiInput) {
            //--- Tagify
            (function () {

                var input = document.querySelector('input[name=tags]');

                // init Tagify script on the above inputs
                window.tagify__basic = new Tagify(input, {
                    whitelist: ["español",
                    "matematicas",
                    "ecologia",
                    "geografía",
                    "medio ambiente",
                    "contaminación",
                    "trabajo en equipo",
                    "debate",
                    "responsabilidad social",
                    "biología",
                    "Literatura",
                    "Creatividad",
                    "cultura",
                    "salúd",
                    "perseverancia",
                    "discusión grupal",
                    "espacio","astronomía",
                    "física",
                    "ciencia",
                    "electricidad",
                    "visitas escolares",
                    "solución de problemas",
                    "química",
                    "genética",
                    "gráficas",
                    "suma y resta",
                    "Lenguaje",
                    "Comunicación",
                    "Arte",
                    "Música",
                    "Civismo",
                    "Ética",
                    "Formación cívica y ética",
                    "Punto decimal",
                    "plano cartesiano",
                    "álgebra",
                    "fracciones",
                    "suma",
                    "resta",
                    "multiplicación",
                    "árbol Ramón",
                    "primaria baja",
                    "primaria media",
                    "primaria alta",
                    "Exploración y comprensión del mundo natural y social",
                    "ecosistemas",
                    "comprensión del mundo natural y social",
                    "Seres vivos",
                    "pensamiento crítico",
                    "resolución de problemas",
                    "uso del mouse",
                    "uso del teclado",
                    "uso de la tableta",
                    "atención",
                    "memoria",
                    "Funciones visoespaciales",
                    "Funciones sensoriomotoras",
                    "Percepcion visual"
                    ],
                    blacklist: [".NET", "PHP"], // <-- passed as an attribute in this demo
                    dropdown: {
                        classname: "basicDropdown",
                        maxItems: 4
                    }
                })


                // Chainable event listeners
                tagify__basic
                    .on('add', onAddTag)
                    .on('remove', onRemoveTag)
                    .on('input', onInput)
                    .on('edit', onTagEdit)
                    .on('invalid', onInvalidTag)
                    .on('click', onTagClick)
                    .on('dropdown:show', onDropdownShow)
                    .on('dropdown:hide', onDropdownHide)

                // tag added callback
                function onAddTag(e) {
                    console.log("onAddTag: ", e.detail);
                    console.log("original input value: ", input.value)
                    tagify__basic.off('add', onAddTag) // exmaple of removing a custom Tagify event
                }

                // tag remvoed callback
                function onRemoveTag(e) {
                    console.log(e.detail);
                    console.log("tagify instance value:", tagify__basic.value)
                }

                // on character(s) added/removed (user is typing/deleting)
                function onInput(e) {
                    console.log(e.detail);
                    console.log("onInput: ", e.detail);
                }

                function onTagEdit(e) {
                    console.log("onTagEdit: ", e.detail);
                }

                // invalid tag added callback
                function onInvalidTag(e) {
                    console.log("onInvalidTag: ", e.detail);
                }

                // invalid tag added callback
                function onTagClick(e) {
                    console.log(e.detail);
                    console.log("onTagClick: ", e.detail);
                }

                function onDropdownShow(e) {
                    console.log("onDropdownShow: ", e.detail)
                }

                function onDropdownHide(e) {
                    console.log("onDropdownHide: ", e.detail)
                }

            })()

            $("#searchByTags").click(
                function () {
                    localStorage.setItem('buscador', JSON.stringify(tagify__basic.value));
                    // console.log("Buscar");
                });


        }
    }, 1800);

}

function responsive() {
}

// INVOCACIÓN

$(document).on('ready', start);
$(window).on('load resize', responsive);

